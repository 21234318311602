
export default {
    data() {
        return {
            banner: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Banner/jpg/25th_Anniversary_Banner-1920@2x.jpg`,
            mobileBanner: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/25th_Anniversary_Mobile-Banner-600@2x.jpg`,
            title: `POWERED BY PASSION`,
            subtitle: `Celebrating 25 Years of Practicing Smart & Playing Hard`
        }
    }
}
