import { render, staticRenderFns } from "./about.vue?vue&type=template&id=6c5c3b28"
import script from "./about.vue?vue&type=script&lang=js"
export * from "./about.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextViewMore: require('/usr/src/app/components/text/view-more.vue').default})
