
export default {
    data() {
        return {
            currentPanel: -1,
            videoIcon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/Video%20Player/clapperboard-play-thin-White.svg`,
            videoModal: 'false',
            panels: [
                {
                    years: `1999 - 2003`,
                    title: `Red Wall & Muffulettas`,
                    rgb_solid: `#FF00AE`,
                    rgb: `#FF00AEB3`,
                    description: `These were our formative years during which we took interactive audio lessons to a whole new level while prepping for the imminent arrival of online video technologies.`,
                    video: `https://www.youtube.com/embed/nYnEZpebwv0?si=mwF8f4S_Sj2ivlRr`,
                },
                {
                    years: `2004 - 2008`,
                    title: `Black & White to Color`,
                    rgb_solid: `#921088`,
                    rgb: `#921088E6`,
                    description: `2004 was a breakthrough year and the start of milestone era featuring cool new digs with a studio (of sorts) and a world of unlimited educational possibilities with interactive videos!`,
                    video: ``,
                },
                {
                    years: `2009 - 2013`,
                    title: `Pixel Proliferation`,
                    rgb_solid: `#701E7D`,
                    rgb: `#701E7DE6`,
                    description: `Another breakthrough era with another new studio (that we built ourselves) and more pixels and resolution and video quality to play with. Over 400 new courses produced with 40 new artistes joining the family!`,
                    video: ``,
                },
                {
                    years: `2014 - 2018`,
                    title: `Turning Pro`,
                    rgb_solid: `#581A7C`,
                    rgb: `#581A7CE6`,
                    description: `We bought a building, gutted it and designed every inch to our spec, including a studio designed for our unique approach. Launched In The Jam and the Channels platforms, plus released over 500 titles in this era!`,
                    video: ``,
                },
                {
                    years: `2019 - 2024`,
                    title: `All in the Family`,
                    rgb_solid: `#3C126A`,
                    rgb: `#3D126AE6`,
                    description: `More good fortune came our way in 2019 when we partnered with GCP who not only had monster business chops, they were passionate players and music learners as well. They led the charge to form the TrueFire Studios family by bringing on ArtistWorks, JamPlay and FaderPro.`,
                    video: ``,
                },
            ]
        }
    },
}
