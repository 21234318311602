
export default {
    data() {
        return {
            navBar: [
                { label: `About`, jump: `about`},
                { label: `Timeline`, jump: `timeline`},
                { label: `Gallery`, jump: `gallery`},
                { label: `Links`, jump: `links`},
            ]
        }
    },
    methods: {
        scrollTo(jump) {
            document.getElementById(jump).scrollIntoView({behavior: "smooth"});
        }
    }
}
