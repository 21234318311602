
export default {
    data() {
        return {
            picks: [
                { 
                    icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/GuitarPicks/GuitarPick_1.svg`,
                    title: `Official 25th Anniversary Merch`,
                    link: `https://truefire.threadless.com/`,
                },
                { 
                    icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/GuitarPicks/GuitarPick_2.svg`,
                    title: `25th Anniversary Promotional Page`,
                    link: `https://play.truefire.com/25th`,
                },
                { 
                    icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/GuitarPicks/GuitarPick_3.svg`,
                    title: `Artist & Educator Roster`,
                    link: `https://truefire.com/educators`,
                },
                { 
                    icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/GuitarPicks/GuitarPick_4.svg`,
                    title: `TrueFire Apps`,
                    link: `https://truefire.com/apps`,
                },
                { 
                    icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/GuitarPicks/GuitarPick_5.svg`,
                    title: `TrueFire Studios`,
                    link: `https://www.truefirestudios.com/`,
                },
            ],
            icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/TF%20Avatar/25th-Anniversary_TF-Avatar-Black.svg`
        }
    }
}
