
	
	import Hls from 'hls.js';

	export default {
		props: {
			videoUrl: String,
			playCover: String,
		},
    	data() {
			return {
				started: false,
				video: ``,
				beforePlay: true, 	// only display big play button before it starts playing
				isPlaying: false,
				progressBar: "", 	// saved to update style
				timeRemaining: `00:00`,
				progressPercentage: 0,
				startTime: 0,
				updatingBar: false,	// condition to guarantee progress bar styling updates on user click
				showVolumeSlider: false,
			}
		},
		watch: {
			videoUrl(v) {
				console.log(v);
				this.destroyHls();
				this.$nextTick(() => {
					console.log("next tick");
					this.updateVideo();
				});
				/*this.hls = 
				this.hls.loadSource(v);
				this.isPlaying = false;
				this.video.pause();
				this.beforePlay = true;*/
				/*this.hls.loadSoure(v);
				console.log(this.hls)
				this.isPlaying = false;
				this.video.pause();
				this.beforePlay = true;*/
				
				//this.initVideo();
			}
		},
		methods: {
			destroyHls() {
                this.hls.stopLoad();
                this.hls.detachMedia();
                this.hls.destroy();
            },
			/* assures progress bar updates instantaneously on click */
			updateBar() {
				const now = new Date();
				const nowTime = now.getTime();
				this.startTime = nowTime - 1100; // represents > 1 second
			},
			jumpBackward() {
				if (this.video.currentTime > 10) {
					this.video.currentTime -=  10;
				} else {
					this.video.currentTime = 0;
				}
				this.updateBar();
			},
			jumpForward() {
				if ((this.video.duration - this.video.currentTime) > 10) {
					this.video.currentTime += 10;
				} else {
					this.video.currentTime = this.video.duration;
				}
				this.updateBar();
			},
			/* fade play button when hovering over video div */
			hoverOn() {
				if (this.beforePlay) {
					let bigPlay = this.$refs.bigPlay;
					bigPlay.classList.remove("opacity-100");
					bigPlay.classList.add("opacity-75");
				}
			},
			hoverOff() {
				if (this.beforePlay) {
					let bigPlay = this.$refs.bigPlay;
					bigPlay.classList.remove("opacity-75");
					bigPlay.classList.add("opacity-100");
				}
			},
			togglePlay() {
				if(!this.started){
					this.initVideo()
				} else {
					if (this.isPlaying) {
						this.video.pause();
					} else {
						this.video.play();
					}
					this.beforePlay = false;
					this.isPlaying = !this.isPlaying;
				}

			},
			endPause() {
				/* display play button at end of video */
				this.video.pause();
				this.isPlaying = false;
			},
			showTimeRemaining() {
				let timeRemaining = this.video.duration - this.video.currentTime;
				if (isNaN(timeRemaining)) { 
					timeRemaining = "0"; 
				}
				let minutesRemaining = Math.floor(timeRemaining / 60) ;
				let secondsRemaining = Math.floor(timeRemaining % 60);
				if (minutesRemaining < 10) {
					minutesRemaining = '0' + minutesRemaining;
				}
				if (secondsRemaining < 10) {
					secondsRemaining = '0' + secondsRemaining;
				}
				this.timeRemaining = minutesRemaining + ":" + secondsRemaining;
			},
			handleProgress() {
				this.showTimeRemaining();

				const now = new Date();
				const nowTime = now.getTime();
				/* assures progress bar updates instantaneously on click or when video url has changed */
				if (this.updatingBar) {
					this.updateBar();
				}
				/* when playing, update progress bar every second */
				if ((nowTime - this.startTime) > 1000) {
					this.startTime = nowTime;
				} else if (this.video.duration != this.video.currentTime) {
					return;
				} 
				let progressPercentage = Math.floor((this.video.currentTime / this.video.duration) * 100);
				if (isNaN(progressPercentage)) { 
					progressPercentage = 0;
				}
				this.progressPercentage = progressPercentage;
				this.$refs.progressBar.setAttribute("style", `background: linear-gradient(to right, rgb(166, 75, 30) 0%, rgb(166, 75, 30) ${this.progressPercentage}%, rgb(51, 51, 51) ${this.progressPercentage}%, rgb(51, 51, 51) 100%);`);
			},
			moveProgress() {
				this.updatingBar = true;
				const newTime = this.video.duration * (this.$refs.progressBar.value / 100);
				this.video.currentTime = newTime;
				this.handleProgress();
				this.updatingBar = false;
			},
			updateVolume(value) {
				/* method triggered in volume-slider child component */
				this.video.volume = value / 100;
			},
			toggleVolumeSlider() {
				this.showVolumeSlider = !this.showVolumeSlider;
			},
			showFullScreen() {
				/* default */
				if (this.video.requestFullscreen) {
					this.video.requestFullscreen();
				} 
				/* safari */
				else if (this.video.webkitRequestFullscreen) { 
					this.video.webkitRequestFullscreen();
				} 
				/* IE11 */
				else if (this.video.msRequestFullscreen) {
					this.video.msRequestFullscreen();
  				}
			},
			resetVideo() {
				this.video.currentTime = 0;
				this.video.pause();
				this.isPlaying = false;
				this.beforePlay = true;
				this.updatingBar = true;
				this.handleProgress();
				this.updatingBar = false;
			},
			updateVideo() {
				this.resetVideo();
				try {
					const hls = new Hls();
					hls.loadSource(this.videoUrl);
					hls.attachMedia(this.video);					
				}
				catch {
					console.log("error handling video");
					this.videoUrl = ``;
				}
			},
			initVideo() {
				try {
					this.video = this.$refs.video;
					this.hls = new Hls();
					this.hls.loadSource(this.videoUrl);
					this.hls.attachMedia(this.video);
					this.video.currentTime = 0;

					/* time stamp to only update progress bar every one second when video is playing */
					const start = new Date();
					this.startTime = start.getTime();

					this.handleProgress();
					if(!this.started){
						this.started = true
						this.$nextTick(this.togglePlay)
					}
				}
				catch {
					console.log("error handling video");
					this.videoUrl = ``;
				}
			}
		},
		created() {
			// this.initVideo();
		},
}
