
  export default {
    data() {
      return {
        icon: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Icons/Gallery/25th-Anniversary_Gallery-Icon-Black.svg`,
        col1: [0,2,3],
        col2: [1,4,5],
        col3: [6,7,8,9],
        gallery: [
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Brad%20Carlton%20and%20SWAT%20Students%20in%20discussing%20deep%20thoughts.jpg`,
            caption: `Brad Carlton and SWAT Students discussing deep thoughts`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Brad%20on%20location%20with%20Robben%20Ford%20in%20Nashville.jpg`,
            caption: `Brad on location with Robben Ford in Nashville`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Burning%20Discs%20-%20Yes,%20It_s%20true.jpg`,
            caption: `Burning Discs - Yes, It's true`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Early%20Robotic%20Setup.jpg`,
            caption: `Early Robotic Setup`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/First%20Round%20App%20Designs.jpg`,
            caption: `First Round App Designs`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Gen%202%20Studio%20Under%20Way.JPG`,
            caption: `Gen 2 Studio Under Way`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Gen%202%20Studio.jpg`,
            caption: `Gen 2 Studio`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Guitar%20Lounge.png`,
            caption: `Guitar Lounge`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Holiday%20Shenanigans%20at%20HQ.jpg`,
            caption: `Holiday Shenanigans at HQ`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Jeff%20McErlain%20and%20Cate%20the%20Blues%20Beagle.jpg`,
            caption: `Jeff McErlain and Cate the Blues Beagle`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Jeff%20Scheetz%20and%20Ren%20Wright%20Smackdown.JPG`,
            caption: `Jeff Scheetz and Ren Wright Smackdown`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Epic%20Muriel%20Anderson%20All%20Star%20Guitar%20Night%20Crew.jpg`,
            caption: `Epic Muriel Anderson All Star Guitar Night Crew`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/First%20Audio%20Course%20Compilation%20-%20Acoustic%20U.jpg`,
            caption: `First Audio Course Compilation - Acoustic U`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/First%20Jazz%20Camp%20Artist%20Alum%20-%20Frank%20Vignola%20-%20Sheryl%20Bailey%20-%20Tim%20Lerch.jpg`,
            caption: `First Jazz Camp Artist Alum - Frank Vignola - Sheryl Bailey - Tim Lerch`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Late%20Night%20Post%20All%20Star%20Guitar%20Night%20Waffles%20in%20Nashville.jpeg`,
            caption: `Late Night Post All Star Guitar Night Waffles in Nashville`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Lighting%20our%20Fire%20at%20the%20New%20HQ.JPG`,
            caption: `Lighting our Fire at the New HQ`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Loves%20like%20Jagger.JPG`,
            caption: `Loves like Jagger`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Copper%20Mountain%20Music%20Festival.jpg`,
            caption: `Copper Mountain Music Festival`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Crazy%20Covid%20Days.jpg`,
            caption: `Crazy Covid Days`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Early%20All%20Star%20Guitar%20Night%20honoring%20Les%20Paul.jpg`,
            caption: `Early All Star Guitar Night honoring Les Paul`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Early%20Camera%20Days.JPG`,
            caption: `Early Camera Days`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Early%20Days%20at%20the%20Fire.JPG`,
            caption: `Early Days at the Fire`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Early%20On%20Location%20Shoots%20in%20Nashville%20-%20Reeves%20Gabrels.JPG`,
            caption: `Early On Location Shoots in Nashville - Reeves Gabrels`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Lunching%20at%20the%20Fire.JPG`,
            caption: `Lunching at the Fire`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Memorable%20on%20location%20shoots%20-%20Jeff%20Beck.jpg`,
            caption: `Memorable on location shoots - Jeff Beck`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/On%20Location%20with%20Reeves%20Gabrels.JPG`,
            caption: `On Location with Reeves Gabrels`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Our%20daily%20mantra.JPG`,
            caption: `Our daily mantra`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Picks.png`,
            caption: `Picks`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Resident%20Professor%20of%20the%20Deep%20-%20Chris%20Buono.jpg`,
            caption: `Resident Professor of the Deep - Chris Buono`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Riff%20Journal%20will%20always%20be%20our%20favorite%20read.jpg`,
            caption: `Riff Journal will always be our favorite read`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Rocket%20Fuel%20at%20TrueFire%20HQ.JPEG`,
            caption: `Rocket Fuel at TrueFire HQ`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Sponsoring%20the%20epic%20traveling%20stage.jpg`,
            caption: `Sponsoring the epic traveling stage`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Stephen%20Bennett%20and%20his%20brother%20Brad.jpg`,
            caption: `Stephen Bennett and his brother Brad`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Summer%20NAMM%20Night%20Patrol%20in%20Nashville.jpg`,
            caption: `Summer NAMM Night Patrol in Nashville`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/The%20Red%20Wall.jpg`,
            caption: `The Red Wall`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/The%20Studio%20Evolves.jpg`,
            caption: `The Studio Evolves`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Exhibits%20at%20the%20Folk%20Alliance.jpg`,
            caption: `TrueFire Exhibits at the Folk Alliance`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20HQ%20Galley.jpg`,
            caption: `TrueFire HQ Galley`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20HQ.jpg`,
            caption: `TrueFire HQ`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Mercantile.png`,
            caption: `TrueFire Mercantile`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Pixelsticks.JPG`,
            caption: `TrueFire Pixelsticks`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Student%20Swat%20Team%20Visits%20the%20Home%20Office.jpg`,
            caption: `TrueFire Student Swat Team Visits the Home Office`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Takes%20Bulgaria%20by%20Storm.jpg`,
            caption: `TrueFire Takes Bulgaria by Storm`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/TrueFire%20Tech%20on%20Fire.png`,
            caption: `TrueFire Tech on Fire`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Unforgettable%20Moments%20in%20Time%20at%20Soave,%20Italy.jpg`,
            caption: `Unforgettable Moments in Time at Soave, Italy`
          },
          { img: `https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/Gallery/Vicki%20Genfan%20and%20Lucky%20in%20the%20Studio.jpg`,
            caption: `Vicki Genfan and Lucky in the Studio`
          },
        ],
        imageCarousel: false
      };
    }
  }
  