
export default {
    head() {
        console.log('head of 25-years')
        return {
            link: [
                { rel: 'icon', type: 'image/x-icon', href: 'https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/25th-Anniversary-Favicon.svg', key: 'favicon' },
            ],
            // You can also remove the existing favicon by specifying `key`
            meta: [
                { hid: 'icon', rel: 'icon', href: 'https://d2xkd1fof6iiv9.cloudfront.net/images/promos/anniversary/25th-Anniversary-Favicon.svg', type: 'image/x-icon' },
            ],
        }
    }
}
