
    const __default__ = {
        props: {
            text: String,
            numLines: {
                type: Number,
                default: 6
            },
            fontClass: {
                type: String,
                default: ``
            }
        },
        data() {
            return {
                showAll: false,
                showHTML: {
                    text: `More`,
                    icon: `angle-down`
                },
                moreHTML: {
                    text: `More`,
                    icon: `angle-down`
                },
                lessHTML: {
                    text: `Less`,
                    icon: `angle-up`
                },
            }
        },
        watch: {
            showAll(show) {
                if (!show) this.showHTML = this.moreHTML;
                else this.showHTML = this.lessHTML;
            }
        }
    }

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "87aff45a": (_vm.numLines)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__