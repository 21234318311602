
export default {
    data() {
        return {
            about: `
                2024 marks our 25th year of collaborating with world-class guitarists, dreaming up new learning experiences, and interacting with students who have chosen to share their joy of making music with us.
                <br/><br/>
                Twenty-five years is a really long time to be doing anything, especially when you’re doing it with the same people. How did time fly by so fast? 
                <br/><br/>
                I risk sounding like a motivational poster in the office kitchen, but it’s true: "Do what you love.” We must have won the Double Good Fortune prize because not only have we been doing what we love for twenty-five years, we’ve been doing it with the people we love.
                <br/><br/>
                There’s no better way to celebrate our 25th birthday than to recognize and thank the talented artists we partner with, the team we work with, and our cherished “Friends of the ‘Fire” like yourself. These are the people whose support, loyalty, and kind words made and still make time fly.
                <br/><br/>
                I send you this message of love and appreciation on behalf of my co-partners, our entire team, and all of TrueFire’s artists, whom you have also supported in keeping their music alive and well.
                <br/><br/>
                Grab your guitar, and let's go another twenty-five.
                <br/><br/>
                <img src="https://df4emreqpcien.cloudfront.net/images/promos/anniversary/brad-sig.png" width="250px"/>
                <br/><br/>
                Brad
                <br/>
                Founder
            `
        }
    }
}
