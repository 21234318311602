import { render, staticRenderFns } from "./player.vue?vue&type=template&id=161cfb03&scoped=true"
import script from "./player.vue?vue&type=script&lang=js"
export * from "./player.vue?vue&type=script&lang=js"
import style0 from "./player.vue?vue&type=style&index=0&id=161cfb03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "161cfb03",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoVolumeSlider: require('/usr/src/app/components/video/volume-slider.vue').default})
