import { render, staticRenderFns } from "./25th.vue?vue&type=template&id=a1ab5798"
import script from "./25th.vue?vue&type=script&lang=js"
export * from "./25th.vue?vue&type=script&lang=js"
import style0 from "./25th.vue?vue&type=style&index=0&id=a1ab5798&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnniversaryHeader: require('/usr/src/app/components/anniversary/header.vue').default,AnniversaryBanner: require('/usr/src/app/components/anniversary/banner.vue').default,AnniversaryNavigation: require('/usr/src/app/components/anniversary/navigation.vue').default,AnniversaryAbout: require('/usr/src/app/components/anniversary/about.vue').default,AnniversaryTimeline: require('/usr/src/app/components/anniversary/timeline.vue').default,AnniversaryGallery: require('/usr/src/app/components/anniversary/gallery.vue').default,AnniversaryLinks: require('/usr/src/app/components/anniversary/links.vue').default})
