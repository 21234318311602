import { render, staticRenderFns } from "./promocode.vue?vue&type=template&id=385aae20&scoped=true"
import script from "./promocode.vue?vue&type=script&lang=js"
export * from "./promocode.vue?vue&type=script&lang=js"
import style0 from "./promocode.vue?vue&type=style&index=0&id=385aae20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385aae20",
  null
  
)

export default component.exports