
export default {
    props: {
        images: Array
    },
    data() {
        return {
            imageShowing: 0,
        }
    },
    methods: {
        goPrevious() {
            if (this.imageShowing > 0) this.imageShowing -= 1;
            else this.imageShowing = this.images.length - 1;
        },
        goNext() {
            if (this.imageShowing < this.images.length - 1) this.imageShowing += 1;
            else this.imageShowing = 0;
        },
    }
}
